<template>
  <!--    Contribution Form-->
  <validation-observer
    ref="contributionForm"
    v-slot="{invalid}"
  >
    <am-modal
      :title="`${updateContributionMode?'Update':'Create'} Setting`"
      :show="contributionModalOpened"
      :show-overlay="formLoading"
      size="md"
      @onClose="closeContributionModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <am-select
            v-model="selectedContributionId"
            placeholder="All Settings"
            :options="contributionSettings"
            label-name="setting_label"
            value-name="id"
            name="contributions"
            @input="populateContributionForm"
          />
        </div>
        <div class="col-2">
          <am-dropdown
            icon="TrashIcon"
            name="delete_contribution"
          >
            <b-dropdown-item
              id="cd_contribution"
              @click="deleteContribution()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </am-dropdown>
        </div>
        <div class="col-2">
          <am-button
            name="contribution_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @click="resetContributionForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <am-select
        v-model="contribution.transaction_type"
        name="transaction_type"
        label="Transaction Type"
        rules="required"
        :options="transactionTypes"
      />
      <am-select
        v-model="contribution.pricing_level"
        name="pricing_level"
        label="Pricing Level"
        rules="required"
        :options="pricingLevels"
      />
      <am-input
        v-model="contribution.amount"
        name="amount"
        :label="`Amount (${currencySymbol})`"
        rules="required|numeric"
      />
      <am-select
        v-model="contribution.contribution_season"
        name="contribution season"
        label="Contribution Season"
        rules="required"
        :options="seasons"
      />
      <am-date-picker
        id="active_from"
        v-model="contribution.active_from"
        name="active_from"
        label="Active From"
        rules="required"
      />
      <am-date-picker
        id="active_to"
        v-model="contribution.active_to"
        name="active_to"
        label="Active To"
        rules="required"
      />
      <am-input
        v-if="individualContributor"
        v-model="contribution.contributor_id"
        name="contributor"
        label="Contributor ID"
        rules="required"
        suffix-icon="SearchIcon"
        :readonly="!!contributor"
        @onSuffixIconClick="getContributorDetails"
        @onEnterPressed="getContributorDetails"
      />
      <div
        v-if="individualContributor && contributor"
        class="d-flex align-items-center"
      >
        <am-profile-photo
          size="30"
          :photo="contributor.photo"
        />
        <div style="margin-right: 5px;" />
        <b-badge variant="light-primary">
          {{ contributor.name }}
        </b-badge>
        <am-button
          icon-only
          variant="flat-danger"
          icon="XIcon"
          @click="clearContributor()"
        />
      </div>
      <div class="mt-2" />
      <b-form-checkbox>
        Alert Contributors (SMS)
      </b-form-checkbox>
      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeContributionModal()"
          />
          <am-button
            name="save_contribution"
            :label="updateContributionMode?'Update':'Create'"
            :disabled="invalid"
            :loading="contributionLoading"
            loading-text="Loading..."
            @click="updateContributionMode?updateContribution():createContribution()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import { serialize } from 'object-to-formdata'
import { ValidationObserver } from 'vee-validate'
import logData from '@/libs/log'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'
import AmDatePicker from '@/farmbusiness/components/AmDatePicker.vue'
import AmProfilePhoto from '@/farmbusiness/components/AmProfilePhoto.vue'

const cont = {
  id: null,
  transaction_type: null,
  pricing_level: null,
  amount: null,
  contribution_season: null,
  active_from: null,
  active_to: null,
  contributor_id: null,
  alert: false,
  is_member_contribution: false,
}
export default {
  name: 'ContributionSettingSetup',
  components: {
    AmProfilePhoto,
    AmDatePicker,
    ErrorDisplay,
    AmModal,
    AmDropdown,
    AmButton,
    AmSelect,
    AmInput,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
    BBadge,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      contributionModalOpened: false,
      updateContributionMode: false,
      contributionLoading: false,
      formLoading: false,
      selectedContributionId: null,
      contribution: { ...cont },
      transactionTypes: [
        { label:'Member Dues',value:'Member Dues' },
        { label:'Farmer Group Dues',value:'Organization Dues' },
        { label:'Pension Contribution',value:'Pension Contribution' },
        { label:'Farmer Group Registration Fee',value:'Organization Registration Fee' },
      ],
      seasons: [],
    }
  },
  computed: {
    memberContribution() {
      return this.transactionType?.includes('Member') || this.transactionType?.includes('Pension')
    },
    individualContributor() {
      return this.contribution.pricing_level?.includes('Individual')
    },
    transactionType() {
      return this.contribution.transaction_type
    },
    pricingLevels() {
      if (this.contribution.transaction_type) {
        if (this.contribution.transaction_type === 'Member Dues'
            || this.contribution.transaction_type === 'Pension Contribution') {
          return ['All Members', 'Individual Member']
        }
        return [{ label:'All Farmer Groups',value:'Individual Farmer Group' }, { label:'Individual Farmer Group',value:'Individual Organization' }]
      }
      return []
    },
    currencySymbol() {
      return 'GHS'
    },
    selectedContribution() {
      const contribution = this.contributionSettings.find(o => o.id === this.selectedContributionId)
      if (!contribution) {
        return null
      }
      return {
        ...contribution,
      }
    },
    contributionSettings() {
      return this.$store.getters['contributionSettings/contributionSettings']
    },
    contributor() {
      return this.$store.getters['contributionSettings/contributor']
    },
  },
  watch: {
    modalOpened(opened) {
      this.contributionModalOpened = opened
    },
    id(id) {
      this.selectedContributionId = id
    },
    transactionType(type) {
      if (!this.transactionTypes.includes(type)) {
        this.contribution.pricing_level = null
      }
      this.clearContributor()
    },
  },
  mounted() {
    this.createSeasons()
  },
  methods: {
    clearContributor() {
      this.$store.commit('contributionSettings/setContributor', null)
    },
    createSeasons() {
      const yearLimit = new Date().getUTCFullYear()
      for (let i = 2022; i <= yearLimit + 1; i++) {
        this.seasons.push(`${i}/${i + 1}`)
      }
    },
    resetContributionForm() {
      this.contribution = { ...cont }
      this.selectedContributionId = null
      this.updateContributionMode = false
      this.$refs.contributionForm.reset()
    },
    populateContributionForm(contribution) {
      if (this.selectedContribution) {
        this.updateContributionMode = true
        this.contribution = { ...this.selectedContribution, alert: false }
      } else {
        this.resetContributionForm()
      }
    },
    closeContributionModal() {
      this.contributionModalOpened = false
      this.$emit('modalClosed')
    },
    getContributorDetails() {
      if (!this.contributor) {
        this.formLoading = true
        const id = this.contribution.contributor_id
        if (id) {
          if (this.memberContribution) {
            this.$store.dispatch('contributionSettings/getMember', id).catch(err => {
              this.error = err
            }).finally(() => {
              this.formLoading = false
            })
          } else {
            this.$store.dispatch('contributionSettings/getOrganization', id).catch(err => {
              this.error = err
            }).finally(() => {
              this.formLoading = false
            })
          }
        } else {
          this.showInfo('Enter Contributor ID')
        }
      }
    },
    createContribution() {
      this.error = {}
      this.contributionLoading = true
      if (!this.individualContributor) {
        this.contribution.contributor_id = null
      }
      this.contribution.is_member_contribution = this.memberContribution
      this.$store.dispatch('contributionSettings/createContribution', this.contribution)
        .then(res => {
          this.showSuccess('Created contribution successfully')
          this.resetContributionForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create contribution')
        }).finally(() => {
          this.contributionLoading = false
        })
    },
    updateContribution() {
      this.error = {}
      if (!this.contribution.id) {
        return
      }
      if (!this.individualContributor) {
        this.contribution.contributor_id = null
      }
      this.contribution.is_member_contribution = this.memberContribution
      this.contributionLoading = true
      this.$store.dispatch('contributionSettings/updateContribution', this.contribution)
        .then(res => {
          this.showSuccess('Updated contribution successfully')
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update contribution')
        }).finally(() => {
          this.contributionLoading = false
        })
    },
    deleteContribution() {
      if (!this.selectedContributionId) {
        this.showInfo('Please select a contribution')
        return
      }
      this.formLoading = true
      this.$store.dispatch('contributionSettings/deleteContribution', this.selectedContributionId)
        .then(() => {
          this.showSuccess('Deleted Successfully')
          this.resetContributionForm()
        })
        .catch(() => {
          this.showError('Failed to delete contribution')
        }).finally(() => {
          this.formLoading = false
        })
    },
  },
}
</script>
