<template>
  <div class="">
    <am-list-page
      title="Contribution Settings"
      :columns="columns"
      :rows="settings"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <am-button
            icon="SettingsIcon"
            label="Settings"
            tool-tip-text="Setup & Manage Contribution Settings"
            class=""
            @click="showSettingSetupModal()"
          />
        </div>
      </template>
      <template #contributor="{row}">
        <div v-if="row.item.contributor">
          {{ row.item.contributor }}
        </div>
        <b-badge
          v-else
          variant="light-primary"
        >
          N/A
        </b-badge>
      </template>
      <template #amount="{row}">
        {{ row.item.amount | currency }}
      </template>
      <template #transaction_type="{row}">
        {{ row.item.transaction_type.toString().replaceAll("Organization","Farmer Group") }}
      </template>
      <template #pricing_level="{row}">
        {{ row.item.pricing_level.toString().replaceAll("Organization","Farmer Group") }}
      </template>
      <template #status="{row}">
        <b-badge :variant="getStatusVariant(row.item.status)">
          {{ row.item.status }}
        </b-badge>
      </template>
      <template #action="{row}">
        <am-button
          icon="Edit2Icon"
          icon-only
          tool-tip-text="Click to edit"
          variant="flat-primary"
          @click="showSettingModalForEdit(row.item)"
        />
      </template>
    </am-list-page>
    <setting-setup
      :id="selectedSettingId"
      :modal-opened="settingsModalOpened"
      @modalClosed="closeModal('settings')"
    />
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import SettingSetup from '@/views/contribution/settings/ContributionSettingSetup.vue'

export default {
  name: 'Index',
  components: {
    SettingSetup,
    AmListPage,
    AmButton,
    BBadge,
  },
  data() {
    return {
      tableLoading: false,
      settingsModalOpened: false,
      selectedSettingId: null,
    }
  },
  computed: {
    settings() {
      return this.$store.getters['contributionSettings/contributionSettings']
    },
    currencySymbol() {
      return this.$store.getters['company/currencySymbol']
    },
    columns() {
      const symbol = this.currencySymbol
      return [
        {
          label: 'Transaction Type',
          key: 'transaction_type',
        },
        {
          label: 'Pricing Level',
          key: 'pricing_level',
        },
        {
          label: `Amount(${symbol})`,
          key: 'amount',
        },
        {
          label: 'Contributor',
          key: 'contributor',
        },
        {
          label: 'Contribution Season',
          key: 'contribution_season',
        },
        {
          label: 'Active From',
          key: 'active_from_label',
        },
        {
          label: 'Active To',
          key: 'active_to_label',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Actions',
          key: 'action',
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('contributionSettings/fetchContributionSettings')
  },
  methods: {
    getStatusVariant(status) {
      if (status === 'In Progress') {
        return 'light-primary'
      }
      if (status === 'Pending') {
        return 'light-warning'
      }
      if (status === 'Ended') {
        return 'light-danger'
      }
      return ''
    },
    showSettingModalForEdit(item) {
      this.selectedSettingId = item.id
      this.settingsModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'settings':
          this.settingsModalOpened = false
          this.selectedSettingId = null
          break
      }
    },
    showSettingSetupModal() {
      this.settingsModalOpened = true
    },
  },
}
</script>

<style scoped>

</style>
