var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('am-list-page',{attrs:{"title":"Contribution Settings","columns":_vm.columns,"rows":_vm.settings,"table-loading":_vm.tableLoading,"show-create":false,"show-search":true},scopedSlots:_vm._u([{key:"contributor",fn:function(ref){
var row = ref.row;
return [(row.item.contributor)?_c('div',[_vm._v(" "+_vm._s(row.item.contributor)+" ")]):_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" N/A ")])]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.amount))+" ")]}},{key:"transaction_type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.item.transaction_type.toString().replaceAll("Organization","Farmer Group"))+" ")]}},{key:"pricing_level",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.item.pricing_level.toString().replaceAll("Organization","Farmer Group"))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(row.item.status)}},[_vm._v(" "+_vm._s(row.item.status)+" ")])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('am-button',{attrs:{"icon":"Edit2Icon","icon-only":"","tool-tip-text":"Click to edit","variant":"flat-primary"},on:{"click":function($event){return _vm.showSettingModalForEdit(row.item)}}})]}}])},[_c('template',{slot:"right-extra-header-actions"},[_c('div',{staticClass:"d-flex align-self-center align-items-start",staticStyle:{"margin-bottom":"-18px"}},[_c('am-button',{attrs:{"icon":"SettingsIcon","label":"Settings","tool-tip-text":"Setup & Manage Contribution Settings"},on:{"click":function($event){return _vm.showSettingSetupModal()}}})],1)])],2),_c('setting-setup',{attrs:{"id":_vm.selectedSettingId,"modal-opened":_vm.settingsModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('settings')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }